<template>
    <div>
        <div class="uk-padding-small">
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :isLoading.sync="isLoading"
                :pagination-options="pagination"
                :totalRows="remoteData.totalRecords"
                :rows="remoteData.rows"
                :columns="columns"
                :sort-options="{
                    enabled: false,
                }"
                styleClass="vgt-table striped bordered condensed"
            >
                <template slot="loadingContent">
                    <span class="vgt-loading__content">Téléchargement...</span>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import store from '@/store'

export default {
    name: 'SubContractingCompanies',
    data: () => ({
        isLoading: false,
        serverParams: {
            columnFilters: {},
            sort: [],
            page: 1,
            perPage: 10,
        },
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'both',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
        },
        remoteData: {
            rows: [],
            totalRecords: 0,
        },
    }),
    computed: {
        columns: function () {
            let column = [
                {
                    label: '#',
                    field: 'id',
                    sortable: true,
                },
                {
                    label: 'SIREN',
                    field: 'company.registrationNumber',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Société sous-traitante',
                    field: 'company.name',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Code agence sous-traitance',
                    field: 'osCodeAgenceFournisseur',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Agence sous-traitante',
                    field: 'name',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Id Fournisseur',
                    field: 'idFournisseur',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
            ]

            return column
        },
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps)
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage })
            this.loadItems()
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage })
            this.loadItems()
        },
        onSortChange(params) {
            this.updateParams({ sort: params })
            this.loadItems()
        },
        onColumnFilter(params) {
            this.updateParams(params)
            this.loadItems()
        },
        updateValues(params) {
            this.updateParams(params)
            this.loadItems()
        },
        loadItems() {
            this.isLoading = true
            backendApi
                .subContractingCompanies(store.state.login.user.token, this.serverParams)
                .then((data) => {
                    this.remoteData.totalRecords = data.totalRecords
                    this.remoteData.rows = data.rows
                    this.updateParams({
                        page: data.currentPage,
                        perPage: data.maxResult,
                    })
                })
                .catch((e) => {
                    this.isLoading = false
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
    },
    mounted() {
        this.loadItems()
    },
}
</script>
<style></style>
